import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormOnboard from '@components/FormOnboard';
import { api } from '@helpers/api';
import { Navbar, Image, Logo } from './style';
import { useUser } from '@contexts/UserContext';

const useOnboardData = () => {
  const [companyUuid, setCompanyUuid] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [hasCompany, setHasCompany] = useState(false)
  const [onboardStatus, setOnboardStatus] = useState('')
  const {setIsThemeDark} = useUser()
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOnboardData = async () => {
      try {
        const response = await api.get('/onboard/customer')
        
        if (response.data.length) {
          const company = response.data[0]
          setCompanyUuid(company.uuid)
          setOnboardStatus(company.onboard_status)
          setShowForm(true)
          setHasCompany(true)
          setIsThemeDark(false)

          navigate(`/onboard/customer/${company.uuid}`, { state: response.data })
        }       
      } catch (error) {}
    }

    fetchOnboardData()
  }, [navigate])

  return { companyUuid, showForm, hasCompany, onboardStatus }
}

const handleOnboardSubmit = async (companyUuid: string, values: any, navigate: any) => {
  try {
    await api.post(`/onboard/customer/${companyUuid}`, values)
    navigate('/')
  } catch (error) {
  }
}

const Onboard = () => {
  const { companyUuid, showForm, onboardStatus } = useOnboardData()
  const navigate = useNavigate()
  return (
    <>
      <Navbar>
        <Logo src="/assets/images/logo-opendatacenter-white.png" alt="Logo OPEN" data-cy="onboard-logo" />
      </Navbar>
      {onboardStatus === 'WAITING_FIRST_DEVICE_ACCESS_MAIL' && showForm && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Image src="/assets/images/Email-CS_Fluxograma-4.png" alt="Imagem 4 da jornada" 
            style={{ paddingBottom: '2em', paddingTop: '2em' }} data-cy="journey-image"
          />
        </div>
      )}
      {showForm && (
        <FormOnboard 
          companyUuid={companyUuid}
          onClose={() => navigate('/')}
          action={(values: any) => handleOnboardSubmit(companyUuid, values, navigate)}
        />
      )}
    </>
  );
};
export default Onboard
