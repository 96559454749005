import { Row, Col } from 'antd'
import Title from '@common/Title'
import ResponsiveTableCard from '@common/ResponsiveTableCard'
import { columns } from '@components/Order/Table'
import OrderDrawer from '@components/Order/OrderDrawer'
import { ActionsItems } from '@components/Order/Actions'
import { useTranslation } from 'react-i18next'

const Orders = () => {
  const numForm = Math.floor(Math.random() * 10000).toString()
  const { t } = useTranslation()
  return (
    <Row gutter={[16, 16]}>
      <Col xl={12} xs={24}>
        <Title name={t("QUOTES")} />
      </Col>
      <Col span={24}>
        <ResponsiveTableCard
          style={{ width: '100%' }}
          {...{ columns, url: '/order?' }}
        >
          <Col xl={{ span: 2, offset: 22 }} xs={24}>
            <OrderDrawer action={'create'} numForm={numForm}>
              <ActionsItems />
            </OrderDrawer>
          </Col>
        </ResponsiveTableCard>
      </Col>
    </Row>
  )
}

export default Orders
